module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"remarkPlugins":[{"resolve":"remark-emoji","options":{"emoticon":true}}],"gatsbyRemarkPlugins":["gatsby-remark-images","gatsby-plugin-sharp",{"resolve":"gatsby-remark-custom-blocks","options":{"blocks":{"center":{"classes":"center","title":"optional"},"section":{"classes":"section","title":"optional"},"centerSection":{"classes":"centerSection","title":"optional"},"cta":{"classes":"cta","title":"optional"},"info":{"classes":"info","title":"optional"},"textXS":{"classes":"textXS","title":"optional"},"textSM":{"classes":"textSM","title":"optional"},"textMD":{"classes":"textMD","title":"optional"},"textLG":{"classes":"textLG","title":"optional"},"textXL":{"classes":"textXL","title":"optional"}}}},{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: 1.0725rem"}},{"resolve":"gatsby-remark-smartypants"}]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Devan Huapaya","short_name":"Devan Huapaya","start_url":"/","background_color":"#6b37bf","theme_color":"#6b37bf","icon":"src/static/images/male-technologist.png"},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
