import { createGlobalStyle, keyframes } from 'styled-components'
import {colorTransition} from '../../styles/animation'
import {connect} from 'react-redux'
import getColor from 'utils/getColor'

const shift = keyframes`
from {transform:translateX(-10px);}
to {transform:translateX(0);}
`;

export const GlobalStyles = createGlobalStyle`


.squiggle {
  animation: ${shift} .5s linear infinite;
}

  html, body {
    -webkit-font-smoothing: antialiased;
    ${colorTransition}
    color: ${({theme, reduxTheme}) => theme.colorScheme[reduxTheme.colorScheme].primaryFontColor};
    background-color: ${({theme, reduxTheme}) => theme.colorScheme[reduxTheme.colorScheme].primaryColor};
    font-size: 18px;
  
    .styled-nav {
      ${colorTransition}

      background-color: ${({theme, reduxTheme}) => theme.colorScheme[reduxTheme.colorScheme].primaryColor};
    }
    font-variant-ligatures: normal;
  }

  a, .a {
    color: ${props => getColor(props, "secondaryFontColor")};
  }
  h1 {
    color: ${props => getColor(props, "primaryTitleColor")};
  }
  h2 {
    color: ${props => getColor(props, "primaryTitleColor")};
  }
  h3 {
    color: ${props => getColor(props, "primaryTitleColor")};
  }

  .secondaryTitleColor {
    color: ${props => getColor(props, 'secondaryTitleColor')} !important;
  }
  .tertiaryTitleColor {
    color: ${props => getColor(props, 'tertiaryTitleColor')} !important;
  }
  .border-bottom-no-last-child {
    :not(:last-child) {
      border-bottom: 1px solid ${props => getColor(props, 'secondaryColor')} !important;
    }
  }
`

export default connect(({theme}) => ({reduxTheme: theme}))(GlobalStyles)