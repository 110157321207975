
export default ({theme, reduxTheme}, color) => {
  if(!theme || !theme.colorScheme)
    return 

  if(!reduxTheme || !reduxTheme.colorScheme)
    return 
  
  if(!theme.colorScheme[reduxTheme.colorScheme] || !theme.colorScheme[reduxTheme.colorScheme][color])
    return 
  
  return theme.colorScheme[reduxTheme.colorScheme][color]
}