import {combineReducers} from 'redux';

import theme from './theme';

const appReducer = combineReducers({
  theme,
});

let rootReducer = (state, action) => {
  // Change global state here
  // if (action.type === 'LOGOUT') {
  //   state = {
  //     ...state,
  //     account: null,
  //     billing: null,
  //     auth: null
  //   };
  // }

  return appReducer(state, action);
};

export default rootReducer;
