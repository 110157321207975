import { createStore as reduxCreateStore, compose, applyMiddleware } from 'redux';
import throttle from 'lodash/throttle';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import {loadState, saveState} from './localStorage';
import rootReducer from './reducers';

import isDev from '../utils/isDev';

// Load localstorage
const persistedState = loadState();

const composeEnhancers =
  isDev() &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

// const middleware = [thunk];
const enhancers = isDev() ? applyMiddleware(thunk, logger) : applyMiddleware(thunk);
const composedEnhanceres = composeEnhancers(enhancers);

const createStore = () => {
  const store = reduxCreateStore(
    rootReducer,
    persistedState || {},
    composedEnhanceres
  );
    
  // Load global data
  // store.dispatch(loadStats())
  // store.dispatch(loadPlans())
  // store.dispatch(loadLeaders())
  // store.dispatch(listenToWindow())

  store.subscribe(throttle(()=> {
    saveState(store.getState());
  }, 1000));

  return store;
}




export default createStore

