import space from './space'
import {css} from 'styled-components'

/**
 * Timings use standard t-shirt sizes:
 * - xtra-xtra-smort
 * - xtra-smort
 * - smort
 * - medium
 * - long
 * - xtra-long
 */
export const duration = {
  xxs: 0.125,
  xs: 0.15,
  sm: 0.25,
  md: .5,
  lg: 1,
  xl: 2,
  xxl: 4, 
  xxxl: 5
}
export const colorTransition = css`
    transition:  background-color ${duration.lg}s ease-in-out, color ${duration.lg}s ease-in-out;
`

export const xFast = {transition: {duration: duration.xxs}}
export const fast = {transition: {duration: duration.xs}}
export const mediumFast = {transition: {duration: duration.md}}
export const long = {transition: {duration: duration.lg}}
export const extraLong = {transition: {duration: duration.xl}}
export const extraExtraLong = {transition: {duration: duration.xxl * 3  }}

export const fadeIn = {
  initial: {y: 32, z: 10, opacity: 0},
  animate: {y: 0, z: 10, opacity: 1},
}

export const animateChildren = {
  visible: {
    transition: {
      when: "beforeChildren",
      delayChildren: duration.md,
      staggerChildren: duration.md,
    },
  },
}

export const inAndLeft = {
  hidden: { 
    x: space.xl, 
    z: 10,
    opacity: 0.1
  },
  visible: {
    x: 0,
    z: 10,
    opacity: 1,
  },
  ...extraExtraLong
}

export const inAndUp = {
  hidden: { 
    y: space.xl, 
    z: 10,
    opacity: 0.1
  },
  visible: {
    y: 0,
    z: 10,
    opacity: 1,
  },
  ...extraExtraLong
}