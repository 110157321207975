import React from "react"
import { Provider } from "react-redux"
import { ThemeProvider } from 'styled-components'

import theme from './src/styles/theme';
import createStore from "./src/store"

const store = createStore()

class App extends React.Component {
  render() {
    const {element} = this.props;
    
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          {element}
        </ThemeProvider>
      </Provider>
    )
  }
}

export default (globalProps) => <App {...globalProps}/>