/**
 * Get redux state from localstorage 
 * Only called in browser
 */

export const loadState = () => {
  try{
    if(typeof localStorage !== 'undefined') {
      const serializedState = localStorage.getItem('goodToday');

      if (serializedState === null){
        return undefined;
      }
      return JSON.parse(serializedState);
    }

    return null;
  } catch(err){
    console.log("ERROR LOADING STATE: ", err);
    return null;
  }
};

/**
 * Save Redux state
 * @param {S} state 
 */
export const saveState = (state) => {
  try {
    if(typeof localStorage !== 'undefined') {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('goodToday', serializedState);
    }
  }catch(err){
    console.log("ERROR SAVING STATE: ", err);
    console.log("STATE: ", state);
  }
}
