import React from 'react'
import styled from 'styled-components'
import Container from '../components/styled/Container'
import To from '../components/styled/To'
import {connect} from 'react-redux'
import breakpoints from '../styles/breakpoints'
import {codeFont} from '../styles/fonts'
import {Link} from 'gatsby'
import FooterFun from './FooterFun'

const StyledFooter = styled.footer`
  width: 100%;
  position: relative;
  font-family: ${codeFont};
`

const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;

  ${breakpoints.md} {
    flex-direction: row;
    width: 50%;
  }

  ul {
    width: 100%;
    margin-bottom: 1rem;

    ${breakpoints.md} {
      margin-bottom: 0;
    }
  }


  li {
    margin-bottom: 1rem;
  }
  
  ${breakpoints.md} {
    li:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`


const StyledContainer = styled(Container)` 
  padding: 3rem;
  padding-bottom: 3rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${breakpoints.md} {
    flex-direction: row;
  }
`

const Footer = ({reduxTheme}) => {
  return (
    <StyledFooter>
      <FooterFun />
  
      <StyledContainer>
        <StyledNav>
          <ul>
            <li>
              <To href="https://www.linkedin.com/in/devanhuapaya/">
                LinkedIn
              </To>
            </li>
            <li>
              <Link to='/contact'>
                Contact
              </Link>
            </li>
          </ul>
          <ul>
            <li>
              <To href="https://unsplash.com/@devin_photography">
                Photography
              </To>
            </li>
            {/* 
            <li>
              <To href="https://www.instagram.com/devinpapaya/">
                Insta
              </To>
            </li>
            */}
          </ul>
        </StyledNav>


        <span>
          <a href="/">
            © Devan Huapaya 2020
          </a>
        </span>
      </StyledContainer> 
    </StyledFooter>
  )
}

export default connect(({theme}) => ({reduxTheme: theme}))(Footer)
