const playFairDisplay = '"Playfair Display", times, serif;'
const roboto = '"Roboto", Helvetica, Ariel, sans-serif;'
const robotoSlab = '"Roboto Slab", Helvetica, Ariel, sans-serif;'
const montserrat = '"Montserrat", Helvetica, Ariel, sans-serif;'
const sourceCodePro = '"Source Code Pro", monospace;'

// Playing with / may remove later
const cursive = '"Great Vibes", cursive;'

// export const primaryFont = roboto
export const primaryFont = montserrat
export const primaryTitleFont = playFairDisplay
export const secondaryTitleFont = montserrat
export const tertiaryTitleFont = robotoSlab
export const quaternaryTitleFont = montserrat
export const articleFont = montserrat
export const logoFont = robotoSlab
export const codeFont = sourceCodePro

export default {
  primaryFont,
  primaryTitleFont,
  secondaryTitleFont,
  quaternaryTitleFont,
  logoFont,
  codeFont,
  cursive, 
}