import styled, { createGlobalStyle } from 'styled-components'
import {primaryTitleFont, secondaryTitleFont, codeFont} from '../../styles/fonts'
import colors from '../../styles/colors'

const TypeStyles = createGlobalStyle`
  html {
    /* color: ${colors.definitelyGray}; */
  }
  
  h1 {
    /* color: ${props => (props.whiteColor ? 'white' : 'black')}; */
    font-family: ${secondaryTitleFont};
  }

  h2 {
    font-family: ${primaryTitleFont};
  }
  h3 {
    font-family: ${secondaryTitleFont};
  }
  h4 {
    font-family: ${secondaryTitleFont};
  }
  h5 {
    font-family: ${secondaryTitleFont};
  }
  h6 {
    font-family: ${codeFont};
  }
`

export const Code = styled.span`
  font-family: ${codeFont};
`


export default TypeStyles