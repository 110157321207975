import React from "react";
import styled from 'styled-components'
import { Link } from "gatsby";
import Container from './styled/Container'
import { logoFont } from '../styles/fonts'
import ThemeSwitcher from './ThemeSwitcher'

export const NAV_HEIGHT = "70px";

const StyledNav = styled.nav`
  position: fixed;
  top: 0; 
  left: 0;
  width: 100%;
  z-index: 10;
`

const StyledList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  list-style: none;
  height: ${NAV_HEIGHT};
`

const HomeLink = styled(Link)`
  font-family: ${logoFont};
  font-weight: 900;
  text-decoration: none;
`

const Nav = ({reduxTheme, ...rest}) => {
  return (
    <StyledNav {...rest} className='styled-nav' reduxTheme={reduxTheme}>
      <Container>
        <StyledList>
          <li>
            <HomeLink to="/">DH</HomeLink>
          </li>
  
          {/* <li style={{ marginRight: "1em" }}>
            <Link to="/hire-me/">Hire Me</Link>
          </li>
  
          <li style={{ marginRight: "1em" }}>
            <Link to="/workshops/">Workshops</Link>
          </li>
  
          <li style={{ marginRight: "1em" }}>
            <Link to="/photography/">Photography</Link>
          </li> */}
  
          <li>
            <ThemeSwitcher className='a' />
          </li>
        </StyledList>
      </Container>
    </StyledNav>
  );
}

export default Nav