import {SET_COLOR_SCHEME} from '../actions/themeActions'

function account(state = {colorScheme: 'light'}, action) {
  switch (action.type) {
    case SET_COLOR_SCHEME:
      return {
        ...state,
        colorScheme: action.colorScheme,
      }
    default:
      return state
  }
}

export default account;