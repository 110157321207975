export const black = '#000000';
export const white = '#ffffff';

export const definitelyGray = '#333333';
export const basicallyBlack = '#111111';
export const notPink = '#F0B7AE';
export const prettyPurple = '#BB29BB';

export const light = {
  primaryFontColor: definitelyGray,
  secondaryFontColor: basicallyBlack,
  tertiaryFontColor: notPink,
  primaryTitleColor: definitelyGray,
  secondaryTitleColor: notPink,
  tertiaryTitleColor: definitelyGray,
  successFontColor: '',
  warningFontColor: '',
  dangerFontColor: '',
  primaryColor: white,
  secondaryColor: notPink,
  tertiaryColor: definitelyGray,
  primaryButtonColor: notPink,
  calloutColor: white,
  calloutTitleColor: white,
  calloutFontColor: definitelyGray,
  shadowColor: basicallyBlack,
  successColor: '',
  warningColor: '',
  dangerColor: '',
}

export const dark = {
  primaryFontColor: white,
  secondaryFontColor: white,
  tertiaryFontColor: notPink,
  primaryTitleColor: white,
  secondaryTitleColor: notPink,
  tertiaryTitleColor: white,
  successFontColor: '',
  warningFontColor: '',
  dangerFontColor: '',
  primaryColor: definitelyGray,
  secondaryColor: white,
  primaryButtonColor: notPink,
  tertiaryColor: notPink,
  calloutColor: definitelyGray,
  calloutTitleColor: notPink,
  calloutFontColor: white,
  shadowColor: notPink,
  successColor: '',
  warningColor: '',
  dangerColor: '',
}

export const pink = {
  primaryFontColor: definitelyGray,
  secondaryFontColor: white,
  tertiaryFontColor: prettyPurple,
  primaryTitleColor: definitelyGray,
  secondaryTitleColor: white,
  tertiaryTitleColor: prettyPurple,
  successFontColor: '',
  warningFontColor: '',
  dangerFontColor: '',
  primaryColor: notPink,
  secondaryColor: white,
  primaryButtonColor: definitelyGray,
  tertiaryColor: white,
  calloutColor: white,
  calloutTitleColor: white,
  calloutFontColor: definitelyGray,
  shadowColor: definitelyGray,
  successColor: '',
  warningColor: '',
  dangerColor: '',
}

export default {
  light,
  dark,
  pink
}