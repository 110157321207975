import React from 'react'
import styled, {css} from 'styled-components'
import getColor from '../utils/getColor'
import { setColorScheme } from '../store/actions/themeActions'
import { connect } from 'react-redux';
import { colorTransition } from '../styles/animation'
import breakpoints from '../styles/breakpoints'
import SVG from 'react-inlinesvg';
import sun from '../static/icons/sun-toggle.svg'
import moon from '../static/icons/moon-toggle.svg'
import dawn from '../static/icons/dawn-toggle.svg'

const SIZE = '32px'

const StyledSVG = styled(SVG)`
  max-width: 32px;
  cursor: pointer;

  ${breakpoints.md} {
    max-width: 32px;
  }

  > path {
    fill: currentColor;
  }
`

const StyledThemeSwitcher = styled.div`
  ${colorTransition}

  border-radius: 100%;
  width: ${SIZE};
  height: ${SIZE};
  border: 3px solid;
  cursor: pointer;  


  ${({colorScheme, ...rest}) => {
    switch(colorScheme) {
      case 'dark':
          return `
            background-color: ${getColor(rest, 'primaryColor')};
            border-color: ${getColor(rest, 'tertiaryColor')};
          `
      case 'pink':
          return `
            background-color: ${getColor(rest, 'primaryColor')};
            border-color: ${getColor(rest, 'tertiaryColor')};
          `
      case 'light':
          return `
            background-color: ${getColor(rest, 'primaryColor')};
            border-color: ${getColor(rest, 'tertiaryColor')};
          `
    } 
  }}
`

const ThemeSwitcher = ({reduxTheme, setColorScheme}) => {
  if(!reduxTheme || !reduxTheme.colorScheme)
    return null;
  let {colorScheme} = reduxTheme;

  const iconToRender = colorScheme === 'dark' ? moon : 
                      colorScheme === 'pink' ?  dawn : 
                      sun;
                      
  colorScheme = colorScheme === 'dark' ? 'pink' : 
                colorScheme === 'pink' ?  'light' : 
                'dark';
  const nextReduxTheme = { colorScheme }

  
    return (
      <StyledSVG src={iconToRender}  onClick={() => setColorScheme(colorScheme)} />
    )
  // return (
  //   <StyledThemeSwitcher 
  //     colorScheme={colorScheme} 
  //     reduxTheme={nextReduxTheme} 
  //     onClick={() => setColorScheme(colorScheme)} />
  // )
}

const mapStateToProps = ({theme}) => ({reduxTheme: theme})

const mapDispatchToProps = dispatch => ({
  setColorScheme: theme => dispatch(setColorScheme(theme))
})

export default connect(mapStateToProps, mapDispatchToProps)(ThemeSwitcher);
