export const breakpoints = {
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: '576px',
  // Medium screen / tablet
  md: '768px',
  // Large screen / desktop
  lg: '992px',
  // Extra large screen / wide desktop
  xl: '1200px',
}

export default {
  xs: `@media (min-width: ${breakpoints.xs})`,
  sm: `@media (min-width: ${breakpoints.sm})`,
  md: `@media (min-width: ${breakpoints.md})`,
  lg: `@media (min-width: ${breakpoints.lg})`,
  xl: `@media (min-width: ${breakpoints.xl})`
}

