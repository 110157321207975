import styled, {css} from 'styled-components'
import breakpoints from '../../styles/breakpoints'

const containerMaxWidths = {
  sm: 540, 
  md: 720, 
  lg: 960, 
  xl: 1140
}

const Container = styled.div`
  /* display: flex; */
  margin: auto;
  /* flex-direction: ${({row}) => !row && 'column'}; */
  /* background: blue; */
  width: 90%;

  ${({fluid}) => css`
    ${breakpoints.sm} {
      max-width: ${containerMaxWidths.sm}px;
    }
    ${breakpoints.md} {
      max-width: ${containerMaxWidths.md}px;
    }
    ${breakpoints.lg} {
      max-width: ${containerMaxWidths.lg}px;
    }
    ${breakpoints.xl} {
      max-width: ${containerMaxWidths.xl}px;
    }
  `}
`

export default Container