import React from 'react'
import styled from 'styled-components'
import Container from '../components/styled/Container'
import To from '../components/styled/To'
import {connect} from 'react-redux'
import breakpoints from '../styles/breakpoints'
import {codeFont} from '../styles/fonts'
import {Link} from 'gatsby'

import SVG from 'react-inlinesvg';
import snakePlant from '../static/icons/snake-plant.svg'
import monsteraDeliciosa from '../static/icons/monstera-deliciosa.svg'
import monsteraObliqua from '../static/icons/monstera-obliqua.svg'

import mdsl1 from '../static/icons/leaves/mdsl1.svg'
import mdsl2 from '../static/icons/leaves/mdsl2.svg'
import mosl1 from '../static/icons/leaves/mosl1.svg'
import mosl2 from '../static/icons/leaves/mosl2.svg'
import mosl3 from '../static/icons/leaves/mosl3.svg'

const dividerSize = 12.5;

const FooterFunContainer = styled.div`
  padding-top: ${dividerSize/2}rem;
  padding-bottom: ${dividerSize/2}rem;
  width: 100%;
  position: relative;
`

const Divider = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
`

const StyledSVG = styled(SVG)`
  width: ${({width}) => width || dividerSize * 0.75}rem;
  fill: ${({theme, reduxTheme}) => theme.colorScheme[reduxTheme.colorScheme].primaryFontColor};

  max-height: 10rem;
  
  ${breakpoints.md} {
    width: ${({width}) => width || dividerSize}rem;
  }

  > path {
    fill: ${({theme, reduxTheme}) => theme.colorScheme[reduxTheme.colorScheme].primaryFontColor};
  }
`

const plants = [snakePlant, monsteraDeliciosa, mdsl1, mdsl2, mosl1, mosl2, mosl3,]

const FooterFun = ({reduxTheme}) => {
  const randomLeaf = () => Math.floor(Math.random() * plants.length);
  const leaf1 = randomLeaf()
  let leaf2 = randomLeaf()
  while(leaf2 === leaf1){
    leaf2 = randomLeaf()
  }

  
  return (
    <FooterFunContainer>
      <Divider>
        <StyledSVG src={plants[leaf1]} reduxTheme={reduxTheme} />
        {/* <StyledSVG src={mdsl1} reduxTheme={reduxTheme} />  */}
        {/* <StyledSVG src={monsteraObliqua} reduxTheme={reduxTheme}  /> */}
      </Divider>
    </FooterFunContainer>
  )
}

export default connect(({theme}) => ({reduxTheme: theme}))(FooterFun)
