// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-template-js": () => import("/opt/build/repo/src/templates/projectTemplate.js" /* webpackChunkName: "component---src-templates-project-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-hire-me-js": () => import("/opt/build/repo/src/pages/hire-me.js" /* webpackChunkName: "component---src-pages-hire-me-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-old-home-js": () => import("/opt/build/repo/src/pages/old-home.js" /* webpackChunkName: "component---src-pages-old-home-js" */),
  "component---src-pages-photography-js": () => import("/opt/build/repo/src/pages/photography.js" /* webpackChunkName: "component---src-pages-photography-js" */),
  "component---src-pages-project-js": () => import("/opt/build/repo/src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-regular-js": () => import("/opt/build/repo/src/pages/regular.js" /* webpackChunkName: "component---src-pages-regular-js" */),
  "component---src-pages-resume-js": () => import("/opt/build/repo/src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-test-js": () => import("/opt/build/repo/src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-workshops-js": () => import("/opt/build/repo/src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */)
}

