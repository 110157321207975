import React from 'react'
import Helmet from "react-helmet";
import config from "../config/siteConfig";
import maleTechnologist from '../static/images/male-technologist.png'

const Helm = () => {
  return (
    <Helmet title={config.siteTitle}>
      {/* <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Playfair+Display|Source+Code+Pro:400,600,900|Work+Sans:400,600,700,900&display=swap" /> */}
      {/* <link href="https://fonts.googleapis.com/css?family=Merriweather:300,300i,400,400i,700,700i|Montserrat:400,900,900i|Space+Mono&display=swap" rel="stylesheet" / > */}
      {/* <link href="https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700,700i,900|Roboto+Slab|Roboto:400,900&display=swap" rel="stylesheet" /> */}

      <meta name="description" content={config.siteDescription} />
      <link rel="icon" href={maleTechnologist} />
      <link rel="apple-touch-icon" href={maleTechnologist} />
      <meta 
        property="og:type"
        content="website" />
      <meta 
        property="og:title"
        content={config.siteTitle} />
      <meta 
        property="og:description"
        content={config.siteDescription} />
      <meta 
        property="og:image"
        content="/static/fb-share.jpg" />
    </Helmet>
  )
}

export default Helm