import { createGlobalStyle } from 'styled-components'
import { normalize } from 'polished'

export const Normalize = createGlobalStyle`
  ${normalize()}

  html, body {
    -webkit-font-smoothing: antialiased;
  }
  * {
    box-sizing: border-box;
  }
  ul {
    margin-left: 0px !important;
    margin-bottom: 0px !important;
    list-style: none;
  }
`

export default Normalize