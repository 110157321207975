import React, { useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import Nav, {NAV_HEIGHT} from '../components/Nav'
import Helm from '../components/Helm'
import Footer from '../components/Footer'
import Type from '../components/styled/Type'
import GlobalStyles from '../components/styled/GlobalStyles'
import Normalize from '../components/styled/Normalize'
import {connect} from 'react-redux'
import breakpoints from '../styles/breakpoints'
import margin from '../styles/margin'
import styled from 'styled-components'
import {say} from 'cowsay-browser'

import '../styles/style.css'

const duration = 0.5

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration,
      delay: duration,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: { duration },
  },
}

const StyledPage = styled(motion.main).attrs({
  initial: 'initial',
  animate: 'enter', 
  exit: 'exit',
  variants,
})`
  padding-top: calc(${NAV_HEIGHT} + ${margin.md});
  overflow-x: hidden;
  
  ${breakpoints.md} {
    padding-top: calc(${NAV_HEIGHT} + ${margin.md});
  }
`

const Layout = ({ children, location }) => {
  useEffect(() => {
    console.error(say({
      text: 'Sup, nerd??? - dm me on twitter @devinpapaya',
      f: 'dragon-and-cow'
    }))
  }, [])
 
  return (
    <>
      <AnimatePresence key={'animate-page'+location.href}>
        <Helm />
        <GlobalStyles />
        <Type />
        <Normalize />
        <Nav />
        <StyledPage key={'page'+location.href} >
          {children}
          <Footer/>
        </StyledPage>
      </AnimatePresence>
    </>
  )
}

export default connect(({theme}) => ({theme}))(Layout)
